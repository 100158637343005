import React, { useState, useCallback, useRef, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'Assets/images/search-audience.svg';
import { makeStyles } from '@material-ui/core/styles';
import './style.scss';
import { find, debounce } from 'lodash';
import { CustomCheckbox } from 'components';

const useStyles = makeStyles((_theme) => ({
    inputRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #D0D2D5',
            borderRadius: '10px',
            outline: 0,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0029ff',
            outline: 0,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0029ff',
            outline: 0,
        },
        height: '36px',
    },
}));

const Index = ({
    autoCompleteId,
    open,
    setOpen,
    loading,
    width,
    options,
    onChange,
    placeholder,
    listBoxProps,
    selectedOptions,
    maxSelections,
    loadMoreOptions,
    handleInputChange,
    inputValue,
    setInputValue,
    multiple = true,
}) => {
    const listboxRef = useRef(null);
    const [value, setValue] = useState(multiple ? [] : null);
    const classes = useStyles();

    const boldQuery = (str, substr) => {
        const escapedSubStr = substr.replace(/\\/g, '\\\\');
        // Create a regular expression with the escaped substring
        const regex = new RegExp(escapedSubStr, 'gi');
        // Replace all occurrences of the substring with *substring*
        return str.replace(regex, `<b>${substr}</b>`);
    };

    const handleScroll = useCallback(
        debounce(() => {
            const listboxNode = listboxRef.current;
            if (listboxNode) {
                const bottom = listboxNode.scrollHeight - listboxNode.scrollTop === listboxNode.clientHeight;
                if (bottom) {
                    loadMoreOptions();
                }
            }
        }, 200),
        [loadMoreOptions]
    );

    useEffect(() => {
        const listboxNode = listboxRef.current;
        if (listboxNode) {
            listboxNode.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (listboxNode) {
                listboxNode.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, open]);

    return (
        <div className='autocomplete'>
            <Autocomplete
                inputValue={inputValue}
                value={value}
                classes={classes}
                getOptionSelected={(option, optionValue) => option.id === optionValue.id}
                getOptionDisabled={(_option) => {
                    return maxSelections ? selectedOptions?.length === maxSelections : false;
                }}
                closeIcon={null}
                forcePopupIcon={false}
                multiple={multiple}
                ListboxProps={{
                    ...listBoxProps,
                    ref: listboxRef,
                }}
                id={autoCompleteId}
                style={{ width }}
                open={open}
                onOpen={() => setOpen(true)}
                noOptionsText='No results. Try adjusting your search'
                onClose={() => setOpen(false)}
                disableCloseOnSelect={multiple}
                onChange={(_event, newvalue) => {
                    setValue(multiple ? [] : null);
                    // if multiple selection is enabled newvalue will be array else object
                    onChange(multiple ? newvalue[0] : newvalue);
                }}
                onBlur={() => setInputValue('')}
                getOptionLabel={(option) => option.name || ''}
                options={options}
                loading={loading}
                renderOption={(option) => (
                    <div className='item-list'>
                        {multiple && (
                            <CustomCheckbox checked={!!find(selectedOptions, (item) => item.id === option?.id)} />
                        )}
                        {option.picUrl ? (
                            <img className='image' src={option.picUrl} alt='' />
                        ) : (
                            <div className='image-name'>
                                <p className='channel-name'>{option.channel ? '#' : '@'}</p>
                            </div>
                        )}
                        <p
                            className='item-name'
                            dangerouslySetInnerHTML={{ __html: boldQuery(option.name || option.userName, inputValue) }}
                        />
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        placeholder={placeholder}
                        onChange={(e) => {
                            setInputValue(e.target.value.trim());
                            handleInputChange(e.target.value);
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: <SearchIcon className='input-icon' />,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress color='inherit' size={20} className='input-icon' />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};

Index.propTypes = {
    autoCompleteId: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    loading: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    listBoxProps: PropTypes.any,
    selectedOptions: PropTypes.array.isRequired,
    maxSelections: PropTypes.number,
    loadMoreOptions: PropTypes.func,
    handleInputChange: PropTypes.func,
    inputValue: PropTypes.string,
    setInputValue: PropTypes.func,
    multiple: PropTypes.bool,
};

export default Index;
