import React from 'react';
import PropTypes from 'prop-types';
import { TAB_NAMES } from '../constants';
import clsx from 'clsx';

const Tabs = ({ handleTabChange, selectedTab }) => {
    return (
        <div className='dropdown-tabs'>
            {TAB_NAMES.map((tabName, index) => (
                <button
                    key={tabName}
                    className={clsx({ 'tab-name': true, 'tab-name-selected': index === selectedTab })}
                    onClick={() => {
                        handleTabChange(index);
                    }}
                >
                    {tabName}
                </button>
            ))}
        </div>
    );
};

Tabs.propTypes = {
    handleTabChange: PropTypes.func,
    selectedTab: PropTypes.number,
};

export default Tabs;
