import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TermsModal from '../../../RedeemPoints/TermsModal';
import RedeemInstructionModal from '../../../RedeemPoints/RedeemInstructionModal';
import { fetchRedeemHistory } from 'Services/apiFunctions';
import { GET_REDEEM_HISTORY } from 'Services/apiKeys';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { useToaster } from 'Context/SnackbarContext';
import Pagination from 'components/ReusableComponents/Pagination';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import GiftCardCouponModal from 'components/Modals/GiftCardCouponModal';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MultiSelect from 'components/ReusableComponents/MultiSelect';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { GetXoxoday } from 'redux/xoxoday/actions';
import { dateRanges } from 'constants.js';
import { debounce } from 'lodash';
import RedemptionRow from './RedemptionRow';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const EmptyState = ({ noResults }) => {
    const noResultsText = <p>Adjust filter for results!</p>;
    const noOrdersText = (
        <p>
            Currently there are no order purchases to <br />
            showcase. Once you start redeeming gift <br /> cards, you will find them here.
        </p>
    );
    const emptyText = noResults ? noResultsText : noOrdersText;
    return (
        <div className='empty-state'>
            <img src={require('Assets/images/empty-filter.svg')} alt='empty-state' />
            {emptyText}
        </div>
    );
};

const Index = ({ showDateFilter }) => {
    const dispatch = useDispatch();
    const { xoxoday, userCountry } = useSelector(mapStateToProps, shallowEqual);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [couponOpen, setCouponOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [instructionOpen, setInstructionOpen] = useState(false);
    const [tnc, setTnc] = useState('');
    const [ri, setRi] = useState('');
    const [categories, setCategories] = useState([]);
    const [redeemData, setRedeemData] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedCategories, setSelectedCategories] = useState({ category: [], index: {} });
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { SetSnackbar } = useToaster();
    const [dateFilter, setDateFilter] = useState({ date: { label: 'ALL', value: '' } });
    const [search, setSearch] = useState('');
    const CUSTOM_DATE = 'Custom Date';

    const { data: redeemHistoryData, isLoading } = useQuery(
        [GET_REDEEM_HISTORY, { page, search, startDate: dateFilter?.dateStart, endDate: dateFilter?.dateEnd }],
        fetchRedeemHistory,
        {
            onSuccess: (data) => setRedeemData(data?.redeemHistory || []),
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        }
    );

    const toggleCoupon = (index) => {
        setCurrentIndex(index);
        setCouponOpen(true);
    };
    const handleTnc = (content) => {
        setTnc(content);
        setTermsOpen(true);
    };
    const handleRI = (content) => {
        setRi(content);
        setInstructionOpen(true);
    };

    const handleSearchText = debounce((value) => {
        setSearch(value);
        setPage(1);
    }, 300);

    const handleCustomDate = (selectedDate) => {
        const fromDate = format(selectedDate?.startDate, 'yyyy-MM-dd');
        const toDate = format(selectedDate?.endDate, 'yyyy-MM-dd');
        setDateFilter({
            ...dateFilter,
            date: { label: CUSTOM_DATE, value: CUSTOM_DATE },
            dateStart: fromDate,
            dateEnd: toDate,
        });
        setPage(1);
        setShowDatePicker(false);
    };

    const handleFilter = (value) => {
        if (value?.value === CUSTOM_DATE) {
            setShowDatePicker(true);
        } else {
            setDateFilter({
                ...dateFilter,
                date: value,
                dateStart: value.value,
                dateEnd: undefined,
            });
            setPage(1);
        }
    };

    const handleSelection = (_event, index) => {
        const category = xoxoday.categories[index];
        if (selectedCategories.category.includes(category)) {
            const selectedCategoriesIndex = selectedCategories.category.indexOf(category);
            const temp = [...selectedCategories.category];
            temp.splice(selectedCategoriesIndex, 1);
            const tempIndices = { ...selectedCategories.index };
            delete tempIndices[index];
            setSelectedCategories({ category: temp, index: tempIndices });
        } else {
            setSelectedCategories({
                category: [...selectedCategories.category, category],
                index: { ...selectedCategories.index, [index]: true },
            });
        }
    };

    useEffect(() => {
        if (!xoxoday && userCountry) {
            GetXoxoday(dispatch, { country: userCountry, page: 1, initiationStage: 'redeem' }, SetSnackbar);
        }
        if (xoxoday?.categories) {
            const temp = xoxoday.categories.map((category) => ({ label: category, value: category }));
            setCategories(temp || []);
        }
    }, [xoxoday, userCountry]);

    if (isLoading && !search) {
        return <CustomLoader />;
    }

    return (
        <div className='my-recognitions-container purchased-rewards-container'>
            <div className='my-recognitions-filters'>
                {showDateFilter && (
                    <CustomFilterDropdown
                        dropDownID={'redemptionDateDropdown'}
                        title='DATE RANGE'
                        selectedName={dateFilter?.date?.label || 'ALL'}
                        optionsSelected
                        buttonStyleClass='width-188'
                        dropdownHeight={202}
                        filterOptions={dateRanges}
                        handleSelection={(index) => handleFilter(dateRanges[index])}
                        singleSelect
                    />
                )}
                <MultiSelect
                    title='CATEGORIES'
                    className='multi-select-tag-dropdown'
                    itemList={categories}
                    onClick={handleSelection}
                    selectedIndices={selectedCategories.index}
                />
                <div className='ml-auto mt-auto'>
                    <CustomSearchBox
                        width={'320px'}
                        handleSearch={(e) => handleSearchText(e.target.value)}
                        placeholder='Search by gift card name'
                    />
                </div>
            </div>

            <div className='voucher-container-redeem'>
                {redeemData?.map((item, index) => (
                    <RedemptionRow
                        key={item?._id}
                        rowData={item}
                        index={index}
                        handleTnc={handleTnc}
                        handleRI={handleRI}
                        toggleCoupon={toggleCoupon}
                    />
                ))}
                {redeemData.length < 1 && (
                    <EmptyState
                        noResults={
                            (selectedCategories?.length > 0 && redeemData?.length > 0) || dateFilter?.date?.value
                        }
                    />
                )}
            </div>
            <CustomDateRangeModal
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onConfirm={(selectedDate) => handleCustomDate(selectedDate)}
            />
            <GiftCardCouponModal
                open={couponOpen}
                setOpen={setCouponOpen}
                voucherCodes={redeemData?.[currentIndex]}
                tnc={tnc}
                redeemInstructions={redeemData?.[currentIndex]?.redemptionInstructions}
                redeemCoupon={false}
                backButtonText='Back to Redeem History'
            />
            <TermsModal termsOpen={termsOpen} setTermsOpen={setTermsOpen} termsData={tnc} />
            <RedeemInstructionModal
                instructionOpen={instructionOpen}
                setInstructionOpen={setInstructionOpen}
                instructionData={ri}
            />
            {redeemHistoryData?.totalCount > 0 && (
                <Pagination currentPage={page} setCurrentPage={setPage} totalCount={redeemHistoryData?.totalCount} />
            )}
        </div>
    );
};

const mapStateToProps = ({ Xoxoday, User }) => ({
    xoxoday: Xoxoday,
    userCountry: User.userCountry,
});

EmptyState.propTypes = {
    noResults: PropTypes.bool,
};

Index.propTypes = {
    showDateFilter: PropTypes.bool,
};

export default Index;
