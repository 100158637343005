import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import DropdownList from '../DropdownList';
import { AUDIENCE_DROPDOWN_TABS } from '../constants';

const TabDropdown = ({
    search,
    dropdownData,
    dropdownType,
    fetchNextPage,
    hasNextPage,
    audience,
    onChange,
    isEditing,
}) => {
    const getDropdownProps = (page) => {
        if (dropdownType === AUDIENCE_DROPDOWN_TABS.user) {
            return {
                data: page.data.users,
                selectedOptions: audience.userList,
            };
        } else if (dropdownType === AUDIENCE_DROPDOWN_TABS.departments) {
            return {
                data: page.departments,
                selectedOptions: audience.departmentList,
            };
        }
    };

    return (
        (search || dropdownData?.pages?.length > 0) && (
            <div className='dropdown-container-list'>
                <InfiniteScroll
                    dataLength={dropdownData.pages.length * 9}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    height={190}
                    loader={<CustomLoader size={10} />}
                >
                    {dropdownData.pages.map((page, index) => (
                        <DropdownList
                            key={index}
                            {...getDropdownProps(page)}
                            handleSelection={onChange}
                            handleSelectionType={dropdownType}
                            isEditing={isEditing}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        )
    );
};

TabDropdown.propTypes = {
    search: PropTypes.string,
    dropdownData: PropTypes.array,
    dropdownType: PropTypes.string,
    fetchNextPage: PropTypes.func,
    hasNextPage: PropTypes.bool,
    audience: PropTypes.array,
    onChange: PropTypes.func,
    isEditing: PropTypes.bool,
};

export default TabDropdown;
