import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import InputField from 'components/ReusableComponents/InputField';
import { CustomCheckbox } from 'components';
import { useMutation, useQueryClient } from 'react-query';
import { GET_RECOGNITION_TYPES } from 'Services/apiKeys';
import { postRecognitionTypes, updateRecognitionTypes } from '../../../Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { handlePointsUpdate } from '../../componentsUtil';
import { useToaster } from 'Context/SnackbarContext';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import './style.scss';
import { ACCESS_FOR } from '../constants';

const Index = ({
    open,
    addRecognition,
    recognitionData,
    setShowAddOrEditRecognitionTypeModal,
    setAddRecognitionType,
    recognitionTypes,
}) => {
    const getOptions = (data, selectedItems) => {
        return data.map((option) => ({
            ...option,
            label: option,
            isChecked: selectedItems.includes(ACCESS_FOR.USERS) || selectedItems.includes(option),
        }));
    };

    const getSelectedAllowRecognitionForCount = () => {
        if (selectedAllowRecognitionFor.current.length === 0) {
            return 'Choose selection';
        }
        if (
            (selectedAllowRecognitionFor.current.length === 1 &&
                selectedAllowRecognitionFor.current[0] === ACCESS_FOR.USERS) ||
            selectedAllowRecognitionFor.current.length === 3
        ) {
            return ACCESS_FOR.ALL_USERS;
        }
        return selectedAllowRecognitionFor.current.join(' and ');
    };

    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const { mutateAsync: addRecognitionType, isLoading: isAddingRecognitionType } = useMutation(postRecognitionTypes);
    const { mutateAsync: updateRecognitionType, isLoading: isUpdatingRecognitionType } =
        useMutation(updateRecognitionTypes);

    const allowRecognitionForOptions = ['Users', 'Admins', 'Managers'];
    const [approvalRequired, setApprovalRequired] = useState(!addRecognition && recognitionData.isApprovalRequired);
    const selectedAllowRecognitionFor = useRef(
        addRecognition
            ? []
            : recognitionData.allowRecognitionFor.map((item) => item.charAt(0).toUpperCase() + item.slice(1) + 's')
    );
    const [allowRecognitionFor, setAllowRecognitionFor] = useState(
        getOptions(allowRecognitionForOptions, selectedAllowRecognitionFor.current)
    );
    const selectedAllowRecognitionForCount = getSelectedAllowRecognitionForCount();
    const [recognitionName, setRecognitionName] = useState(addRecognition ? '' : recognitionData.name);
    const [recognitionPoints, setRecognitionPoints] = useState(addRecognition ? '' : recognitionData.points);
    const [modifiedFields, setModifiedFields] = useState({
        name: false,
        points: false,
        allowRecognitionFor: false,
        approvalRequired: false,
    });
    const MAX_CHAR_LIMIT = 60;

    const handleAllowRecognitionForSelection = (index) => {
        const temp = [...allowRecognitionFor];
        const isEveryoneSelected = temp[0].label === ACCESS_FOR.USERS && index === 0;

        if (isEveryoneSelected) {
            const newCheckedState = !temp[0].isChecked;
            temp.forEach((option) => (option.isChecked = newCheckedState));
        } else {
            temp[index].isChecked = !temp[index].isChecked;
            if (!temp[index].isChecked) {
                temp[0].isChecked = false;
            }
        }
        selectedAllowRecognitionFor.current = temp.filter((option) => option.isChecked).map((option) => option.label);
        setAllowRecognitionFor(temp);
        if (selectedAllowRecognitionFor.current.length === 3) {
            selectedAllowRecognitionFor.current = [ACCESS_FOR.USERS];
        }
        const changeInDropdownSelection =
            JSON.stringify(selectedAllowRecognitionFor.current) ===
            JSON.stringify(
                recognitionData.allowRecognitionFor?.map((item) => item.charAt(0).toUpperCase() + item.slice(1) + 's')
            );
        setModifiedFields((prevState) => ({
            ...prevState,
            allowRecognitionFor: !changeInDropdownSelection,
        }));
    };

    const onClose = () => {
        setShowAddOrEditRecognitionTypeModal(false);
        setAddRecognitionType(false);
    };

    const getUpdatedAllowRecognitionFor = (selectedOptions) => {
        if (selectedOptions.length === 3) {
            return [ACCESS_FOR.USER];
        }
        return selectedOptions.map((option) => {
            const lowercasedOption = option.toLowerCase();
            return lowercasedOption.slice(0, -1);
        });
    };

    const handleSendRecognitionType = async () => {
        const updatedAllowRecognitionFor = getUpdatedAllowRecognitionFor(selectedAllowRecognitionFor.current);
        try {
            const apiData = {
                name: recognitionName,
                allowRecognitionFor: updatedAllowRecognitionFor,
                points: recognitionPoints,
                isApprovalRequired: approvalRequired,
            };
            await addRecognitionType({ apiData });
            const message = `${recognitionName} recognition type added`;
            showSnackBarMessage(SetSnackbar, 'success', message);
            queryClient.invalidateQueries([GET_RECOGNITION_TYPES]);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
        onClose();
    };

    const handleUpdateRecognitionType = async () => {
        const updatedAllowRecognitionFor = getUpdatedAllowRecognitionFor(selectedAllowRecognitionFor.current);
        try {
            const apiData = {
                ...(recognitionData.name !== recognitionName && { name: recognitionName }),
                ...(JSON.stringify(updatedAllowRecognitionFor) !==
                    JSON.stringify(recognitionData.allowRecognitionFor) && {
                    allowRecognitionFor: updatedAllowRecognitionFor,
                }),
                ...(recognitionData.points !== recognitionPoints && { points: recognitionPoints }),
                ...(recognitionData.isApprovalRequired !== approvalRequired && {
                    isApprovalRequired: approvalRequired,
                }),
                rewardTypeId: recognitionData._id,
            };
            await updateRecognitionType({ apiData });
            queryClient.invalidateQueries([GET_RECOGNITION_TYPES]);
            const message = `${recognitionData.name} recognition type updated`;
            showSnackBarMessage(SetSnackbar, 'success', message);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
        onClose();
    };

    // Data for send feedback modal
    const modalData = {
        heading: `${addRecognition ? 'Add new' : 'Edit'} recognition type`,
        subHeading:
            'Configure your recognitions type, points attributed and access to use it. You can modify this at any point in time',
        rightButtonText: `${addRecognition ? 'Add' : 'Update'}`,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        handleRightButtonClick: addRecognition ? handleSendRecognitionType : handleUpdateRecognitionType,
        disabled: addRecognition
            ? !recognitionName || !recognitionPoints || selectedAllowRecognitionFor?.current?.length === 0
            : !(
                  modifiedFields.name ||
                  modifiedFields.points ||
                  modifiedFields.allowRecognitionFor ||
                  modifiedFields.approvalRequired
              ),
        loading: addRecognition ? isAddingRecognitionType : isUpdatingRecognitionType,
    };

    const isOnlyAdminsSelected =
        selectedAllowRecognitionFor.current.length === 1 &&
        selectedAllowRecognitionFor.current[0] === ACCESS_FOR.ADMINS;

    if (!open) {
        return null;
    }

    return (
        <div>
            <EWModal open={open} onClose={onClose} width='533px' modalData={modalData}>
                <div className='container-recognition-type'>
                    <InputField
                        inputID='recognition-type-name'
                        label='Name of recognition type'
                        labelClass='cr-recognition-type-heading mb-8'
                        placeholder='Enter recognition type name'
                        value={recognitionName}
                        width='478px'
                        height='42px'
                        textArea
                        handleChange={(_id, value) => {
                            if (value.length <= MAX_CHAR_LIMIT) {
                                const name = value.replace(/[\r\n*#@/\\.]/g, '');
                                setRecognitionName(name);
                                setModifiedFields((prevState) => ({
                                    ...prevState,
                                    name: value !== recognitionData.name,
                                }));
                            }
                        }}
                    />
                    <InputField
                        inputID='recognition-type-points'
                        label='Enter the points you want to allocate'
                        labelClass='cr-recognition-type-heading mt-2'
                        placeholder='Enter Points to allocate'
                        value={recognitionPoints}
                        width='480px'
                        height='40px'
                        inputType='number'
                        handleChange={(_id, value) => {
                            handlePointsUpdate({
                                _id,
                                value,
                                SetSnackbar,
                                setRecognitionPoints,
                                setModifiedFields,
                                recognitionData,
                            });
                        }}
                    />
                    <CustomFilterDropdown
                        title='Who can recognise this type?'
                        titleStyleClass='cr-recognition-type-dropdown'
                        buttonStyleClass='mr-0'
                        filterOptions={allowRecognitionFor}
                        dropdownWidth='475px'
                        selectedName={selectedAllowRecognitionForCount}
                        optionsSelected={selectedAllowRecognitionFor?.current?.length !== 0}
                        handleSelection={(index) => handleAllowRecognitionForSelection(index)}
                    />
                </div>
                {!isOnlyAdminsSelected && (
                    <div className='cr-config'>
                        <div>Configurations</div>
                        <div>
                            <CustomCheckbox
                                checked={approvalRequired}
                                label='Approval Required'
                                onClick={() => {
                                    setModifiedFields((prevState) => ({
                                        ...prevState,
                                        approvalRequired: approvalRequired === recognitionData.isApprovalRequired,
                                    }));
                                    setApprovalRequired(!approvalRequired);
                                }}
                            />
                        </div>
                        <div>If checked, respective manager approval is required to send the recognition</div>
                    </div>
                )}
            </EWModal>
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    addRecognition: PropTypes.bool,
    recognitionData: PropTypes.array,
    setShowAddOrEditRecognitionTypeModal: PropTypes.func,
    setAddRecognitionType: PropTypes.func,
    recognitionTypes: PropTypes.array,
};

export default Index;
