import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { find } from 'lodash';
import { addCustomReward, getOrgCountries, getVoucherDetails } from 'Services/apiFunctions';
import { GET_PAGINATED_COUNTRY, GET_REWARDS_DATA } from 'Services/apiKeys';
import TitleBar from 'components/ReusableComponents/TitleBar';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomDatePicker from 'components/ReusableComponents/CustomDatePicker';
import FileSelectionButton from 'components/ReusableComponents/FileSelectionButton';
import EWButton from 'components/ReusableComponents/EWButton';
import Country from 'components/ReusableComponents/Country';
import { CustomSwitch, Tooltip } from 'components';
import InputField from 'components/ReusableComponents/InputField';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import UserSearchDropdown from 'components/ReusableComponents/UserSearchDropdown';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import { showSnackBarMessage, bytesToMb, getCountryFlagURL, checkForValidNumber } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { SAMPLE_FILE_URL, MAX_FILE_SIZE, SUPPORTED_IMAGES, CATEGORYLIST, ERRORTEXT, CATALOG } from '../constant';
import { countryDetails, getCountryDetails } from 'utils/countryUtils';
import { useLocation } from 'react-router-dom';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import './style.scss';
import { format } from 'date-fns';
import { AUDIENCE_DROPDOWN_TABS } from '../../PulseSurvey/CreatePulse/constants';

const Index = ({ history }) => {
    const queryClient = useQueryClient();
    const { userCountry } = useSelector(mapStateToProps, shallowEqual);
    const location = useLocation();
    const productId = location?.state?.productId;

    const reward = useRef();
    const [title, setTitle] = useState({ value: reward?.name || '', error: false });
    const [category, setCategory] = useState({ value: reward?.categories, error: false });
    const [instruction, setInstruction] = useState({ value: reward?.redemptionInstructions || '', error: false });
    const [tnc, setTNC] = useState(reward?.termsAndConditionsInstructions || '');
    const [csvFile, setCSVFile] = useState({ file: undefined, name: reward?.isCustom?.fileName });
    const [image, setImage] = useState({
        file: undefined,
        url: reward?.imageUrl,
        name: reward?.isCustom?.imageName,
    });
    const [expiry, setExpiry] = useState(reward?.expiryAndValidity);
    const [countries, setCountries] = useState([]);
    const [rewardCountries, setRewardCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const pageTitle = `${productId ? 'Edit' : 'Add'} Custom Reward`;
    const buttonText = `${productId ? 'Update' : 'Save'} reward`;

    const { SetSnackbar } = useToaster();

    const showError = (errorMessage, type) => showSnackBarMessage(SetSnackbar, type || 'warning', errorMessage);

    useQuery([GET_PAGINATED_COUNTRY, 1, 100], getOrgCountries, {
        onSuccess: (countryData) => {
            if (countryData?.countries?.length > 0) {
                const countriesData = [];
                countryData.countries.forEach((country) => {
                    const rewardCountry = getCountryDetails(country?.name);
                    if (rewardCountry) {
                        countriesData.push({ ...rewardCountry, label: rewardCountry.countryCode });
                    }
                });
                setCountries(countriesData);
            }
        },
        onError: (error) => showError(error?.message, 'error'),
        refetchOnWindowFocus: false,
    });

    const { mutate: addReward, isLoading } = useMutation(addCustomReward, {
        onSuccess: () => {
            showSnackBarMessage(SetSnackbar, 'success', `Custom reward ${productId ? 'updated' : 'added to catalog'}`);
            queryClient.invalidateQueries(GET_REWARDS_DATA);
            history.push(CATALOG.HOME);
        },
        onError: (err) => showError(err?.message, 'error'),
    });

    const setRewardData = (rewardData) => {
        reward.current = rewardData;
        setTitle({ value: rewardData?.name || '', error: false });
        setCategory({ value: rewardData?.categories, error: false });
        setInstruction({ value: rewardData?.redemptionInstructions || '', error: false });
        setTNC(rewardData?.termsAndConditionsInstructions || '');
        setCSVFile({ file: undefined, name: rewardData?.isCustom?.fileName });
        setImage({ file: undefined, url: rewardData?.imageUrl, name: rewardData?.isCustom?.imageName });
        setExpiry(rewardData?.expiryAndValidity);

        const amount = rewardData?.isCustom?.amount || {};
        const amountCountries = Object.keys(amount).map((countryKey) => {
            const country = find(countryDetails, (item) => item.countryCode === countryKey);
            return {
                ...country,
                country: country?.country,
                price: amount[countryKey].price,
                status: amount[countryKey].status,
            };
        });
        setRewardCountries(amountCountries);
    };

    const getProductDetails = async (id) => {
        setLoading(true);
        try {
            const product = await getVoucherDetails({ id, initiationStage: 'catalog' });
            setRewardData(product?.details);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const validateFields = () => {
        if (!title.value) {
            setTitle({ value: '', error: true });
        }
        if (!category.value) {
            setCategory({ value: '', error: true });
        }

        if (!instruction.value) {
            setInstruction({ value: '', error: true });
        }
    };

    const handleFieldValidation = () => {
        if (!title.value || !category.value || !instruction.value) {
            validateFields();
            showError(ERRORTEXT);
            return true;
        }

        if (!image?.file && !image?.url) {
            showError('Reward image cannot be empty');
            return true;
        }

        if (find(rewardCountries, (country) => !country?.price || !country?.country)) {
            showError('Reward country and amount cannot be empty');
            return true;
        }

        if (
            productId &&
            title.value === reward?.name &&
            category.value === reward?.categories &&
            instruction.value === reward?.redemptionInstructions &&
            tnc === reward?.termsAndConditionsInstructions &&
            expiry === reward?.expiryAndValidity &&
            reward?.imageUrl &&
            !image?.file &&
            reward?.isCustom?.fileName &&
            !csvFile?.file
        ) {
            showError('Nothing to save');
            return true;
        }
        return false;
    };

    const handleAddCountries = () => {
        if (rewardCountries.length >= countries.length) {
            return showError('No more countries to add', 'error');
        }
        if (find(rewardCountries, (country) => country?.price === '')) {
            return showError('Please enter amount for selected country', 'error');
        }
        setRewardCountries([...rewardCountries, { country: '', price: '', status: true, countryCode: '' }]);
    };

    const handleSave = async () => {
        if (handleFieldValidation()) {
            return;
        }

        let formData = new FormData();
        csvFile?.file && formData.append('file', csvFile.file);
        image?.file && formData.append('image', image.file);
        formData.append('title', title.value);
        if (rewardCountries.length > 0) {
            const amountDetails = {};
            rewardCountries.forEach((item) => {
                const { countryCode, price, status } = item;
                amountDetails[countryCode] = { price, status };
            });
            formData.append('amount', JSON.stringify(amountDetails));
        }
        formData.append('category', category.value);
        expiry && formData.append('expiry', format(new Date(expiry), 'yyyy-MM-dd'));
        formData.append('instruction', instruction.value);
        tnc && formData.append('termsAndConditions', tnc);
        formData.append('operation', productId ? 'edit' : 'add');
        if (selectedUsers.length > 0) {
            const emails = [];
            selectedUsers.forEach((item) => {
                emails.push(item.email);
            });
            formData.append('notifyUsers', emails);
        }
        productId && formData.append('voucherId', reward.current?._id);
        addReward(formData);
    };

    const handleImageSelection = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }
        const fileExtension = selectedFile?.name?.slice(selectedFile?.name?.lastIndexOf('.'));
        if (bytesToMb(selectedFile?.size) > MAX_FILE_SIZE) {
            showError(`Image file size should be less than ${MAX_FILE_SIZE}MB`);
        } else if (SUPPORTED_IMAGES.includes(fileExtension)) {
            setImage({ file: selectedFile, url: URL.createObjectURL(selectedFile), name: selectedFile?.name });
        } else {
            showError(`Please upload a ${SUPPORTED_IMAGES.join()} file`);
        }
    };

    const handleCSVSelection = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }
        if (selectedFile.name.includes('.csv')) {
            setCSVFile({ file: selectedFile, name: selectedFile?.name });
        } else {
            showError('Please upload a csv file');
        }
    };

    const handleUserSelection = (user) => {
        user.type = AUDIENCE_DROPDOWN_TABS.user;
        if (find(selectedUsers, (selectedUser) => selectedUser._id === user._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item._id !== user._id));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleImageRemoval = () => setImage(undefined);
    const handleCSVRemoval = () => setCSVFile(undefined);

    const handleExpiry = (date) => setExpiry(date);

    const handleCountryValues = (value, index, keyName) => {
        const tempData = [...rewardCountries];
        let tempValue = value;
        if (keyName === 'price') {
            tempValue = !value || !checkForValidNumber(value) ? '' : value;
            tempData[index][`${keyName}`] = tempValue;
        } else if (keyName === 'country') {
            const countryValue = getCountryDetails(value);
            if (find(rewardCountries, (item) => item?.country === countryValue?.country)) {
                return showError(`${countryValue?.country} is already selected`, 'error');
            }
            if (countryValue) {
                tempData[index].country = countryValue.country;
                tempData[index].countryCode = countryValue.countryCode;
            }
        } else {
            tempData[index][`${keyName}`] = tempValue;
        }
        setRewardCountries(tempData);
    };

    const getSelectedCountry = (country) => {
        if (!country?.country) {
            return <>Select</>;
        }
        return <Country name={country?.countryCode} flagUrl={getCountryFlagURL(country?.country)} />;
    };

    useEffect(() => {
        if (productId) {
            getProductDetails(productId);
        }
    }, []);

    useEffect(() => {
        if (rewardCountries.length === 0 && userCountry && !productId) {
            const country = find(countryDetails, (item) => item.country === userCountry);
            setRewardCountries([{ ...country, country: country?.country, price: '', status: true }]);
        }
    }, [userCountry]);

    if (loading) {
        return <CustomLoader />;
    }

    return (
        <div className='container-custom-reward'>
            <TitleBar title={pageTitle} back onBack={() => history.push(CATALOG.HOME)} />
            <div className='container-create-custom-reward'>
                <div className='container-left'>
                    <InputField
                        inputID='rewardTitle'
                        label='Title'
                        labelClass='form-headings mt-0'
                        inputClass={title.error ? 'border-red' : ''}
                        value={title.value}
                        placeholder='Enter title'
                        width='392px'
                        handleChange={(_id, value) => setTitle({ value, error: false })}
                    />
                    <CustomFilterDropdown
                        title='Category'
                        titleStyleClass='category-heading'
                        filterOptions={CATEGORYLIST.map((item) => ({ label: item, value: item }))}
                        selectedName={category.value || 'Select category'}
                        optionsSelected={!!category.value}
                        handleSelection={(index) => setCategory({ value: CATEGORYLIST[index], error: false })}
                        buttonStyleClass={`mr-0 ${category.error ? 'border-red' : ''}`}
                        dropdownWidth='404px'
                        singleSelect
                    />
                    <div className='cl-reward-amount'>
                        <p className='form-headings'>Amount</p>
                        {rewardCountries.map((country, index) => {
                            return (
                                <div className='d-flex align-items-center mt-2' key={country?.country}>
                                    <div className='cl-reward-input-container'>
                                        <CustomFilterDropdown
                                            dropDownID={'selectCountry'}
                                            filterOptions={countries}
                                            selectedName={getSelectedCountry(country)}
                                            optionsSelected={true}
                                            dropdownWidth='112px'
                                            singleSelect
                                            customSearchText='Search'
                                            handleSelection={(selectedCountryIndex, _option) =>
                                                handleCountryValues(
                                                    countries[selectedCountryIndex]?.country,
                                                    index,
                                                    'country'
                                                )
                                            }
                                        />
                                        <input
                                            className='cl-reward-input-value'
                                            value={country?.price}
                                            type='text'
                                            onChange={(e) => handleCountryValues(e.target.value, index, 'price')}
                                        />
                                    </div>
                                    <CustomSwitch
                                        checked={country?.status}
                                        onClick={() => handleCountryValues(!country?.status, index, 'status')}
                                    />
                                </div>
                            );
                        })}
                        {rewardCountries.length < countries.length && (
                            <EWButton
                                plainTextButton={true}
                                primary={false}
                                buttonStyleClass='cl-add-countries'
                                onClick={handleAddCountries}
                            >
                                + Add more countries
                            </EWButton>
                        )}
                    </div>
                    <p className='form-headings'>Expiry Date (optional)</p>
                    <CustomDatePicker
                        width='392px'
                        value={expiry || null}
                        onChange={handleExpiry}
                        placeholder='Select expiry date'
                        format='dd MMM yyyy'
                    />
                    <p className='form-headings'>Email Id to notify on successful redemption(optional)</p>
                    <UserSearchDropdown
                        dropDownID='search-user'
                        dropdownWidth={540}
                        dropdownHeight={200}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        handleUserSelection={handleUserSelection}
                        searchIcon={false}
                        placeholder='Select users'
                        customAddSearch={true}
                    />
                    <div className='csr-modal-search-users-selected'>
                        <CustomUserTags
                            users={selectedUsers}
                            onRemove={(users) => setSelectedUsers(users)}
                            email={true}
                        />
                    </div>
                    <InputField
                        inputID='rewardInstruction'
                        label='Redemption Instructions'
                        labelClass='form-headings'
                        inputClass={instruction.error ? 'border-red' : ''}
                        value={instruction.value}
                        placeholder='Type redemption instructions here'
                        width='392px'
                        height='104px'
                        textArea
                        handleChange={(_id, value) => setInstruction({ value, error: false })}
                    />
                    <InputField
                        inputID='rewardTnC'
                        label='Terms and conditions'
                        labelClass='form-headings'
                        value={tnc}
                        placeholder='Type terms and conditions here'
                        width='392px'
                        height='104px'
                        textArea
                        handleChange={(_id, value) => setTNC(value)}
                    />
                </div>
                <div>
                    <p className='form-headings mt-0'>Upload bulk coupon codes (optional)</p>
                    <FileSelectionButton
                        buttonText='Import CSV'
                        fileName={csvFile?.name}
                        acceptedFiles={'.csv'}
                        handleFileSelection={handleCSVSelection}
                        handleRemoveFile={handleCSVRemoval}
                    />
                    <div className='download-sample-file'>
                        <a
                            href={SAMPLE_FILE_URL}
                            rel='noopener noreferrer'
                            target='_blank'
                            className='drop-zone-download'
                        >
                            <CloudDownloadIcon />
                            Download CSV Template
                        </a>
                    </div>
                    <div className='add-image'>
                        <div className='d-flex'>
                            <p className='form-headings'>Upload reward image</p>
                            <Tooltip title={`Accepted formats- jpeg, png below ${MAX_FILE_SIZE}MB`}>
                                <InfoIcon className='margin-info' />
                            </Tooltip>
                        </div>
                        <FileSelectionButton
                            buttonText='Import Image'
                            fileName={image?.name}
                            acceptedFiles={SUPPORTED_IMAGES.join()}
                            handleFileSelection={handleImageSelection}
                            handleRemoveFile={handleImageRemoval}
                        />
                        {image?.url && <img src={image.url} alt='' />}
                    </div>
                </div>
            </div>
            <div className='custom-reward-button-container'>
                <EWButton
                    buttonStyleClass='ml-3'
                    buttonText={buttonText}
                    onClick={handleSave}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ userCountry: state.User.userCountry });

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
