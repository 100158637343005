import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox } from 'components';
import { AUDIENCE_DROPDOWN_TABS } from '../constants';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { find } from 'lodash';
import clsx from 'clsx';
import './style.scss';

const DropdownList = ({ data, selectedOptions, handleSelection, handleSelectionType, selectedTab }) => {
    if (!data) return null;

    const checkIsSelected = (item) => {
        // using this function to deal with inconsistencies of id and _id
        switch (handleSelectionType) {
            case AUDIENCE_DROPDOWN_TABS.user:
                return (
                    !!find(selectedOptions, (selectedUser) => selectedUser.id === item._id) ||
                    !!find(selectedOptions, (selectedUser) => selectedUser._id === item._id)
                );
            case AUDIENCE_DROPDOWN_TABS.channels:
                return !!find(selectedOptions, (selectedUser) => selectedUser.id === item.id);
            case AUDIENCE_DROPDOWN_TABS.departments:
                return (
                    !!find(selectedOptions, (selectedUser) => selectedUser._id === item.id) ||
                    !!find(selectedOptions, (selectedUser) => selectedUser.id === item.id)
                );
            default:
                return !!find(selectedOptions, (selectedUser) => selectedUser._id === item._id);
        }
    };

    return (
        <div className='dropdown-container-items'>
            {data.map((item, index) => {
                let isSelected = checkIsSelected(item);
                const displayValue = `${item.name || item.userName || item.channelName}${
                    selectedTab === 2 ? "'s team" : ''
                }`;
                return (
                    <div
                        key={item?._id || index}
                        className={clsx({
                            'dropdown-item-not-selected': !isSelected,
                            'dropdown-item-is-selected': isSelected,
                        })}
                        onClick={() => handleSelection(item, handleSelectionType)}
                    >
                        <div className='width-100'>
                            <CustomCheckbox checked={isSelected} />
                            <EllipsisText text={displayValue} white maxWidth='350px' />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

DropdownList.propTypes = {
    data: PropTypes.array,
    selectedOptions: PropTypes.array,
    handleSelection: PropTypes.func,
    handleSelectionType: PropTypes.string,
    selectedTab: PropTypes.number,
};

export default DropdownList;
