export const TITLE = {
    ACTIVITIES: 'Activities',
    MYACTIVITIES: 'My Activities',
};

export const STATUS = {
    RECEIVED: 'received',
    GIVEN: 'given',
    REQUESTED: 'requested',
    COMPLETED: 'compeleted',
    PENDING: 'pending',
    REJECTED: 'rejected',
    APPROVED: 'approved',
};

export const PAGESTATE = {
    ALLACTIVITIES: 'ALLACTIVITIES',
    MYACTIVITIES: 'MYACTIVITIES',
};

export const TAB_STATES = {
    RECOGNITION: 'RECOGNITION',
    REDEMPTIONS: 'REDEMPTIONS',
    AWARDS: 'AWARDS',
};

export const STATUS_FILTER_DATA = [
    { isChecked: false, label: 'Sent', value: 'sent' },
    { isChecked: false, label: 'Cancelled', value: 'cancelled' },
    { isChecked: false, label: 'Expired', value: 'expired' },
    { isChecked: false, label: 'Approval Pending', value: 'approval_pending' },
    { isChecked: false, label: 'Rejected', value: 'rejected' },
];

export const MY_ACTIVITIES_RECOGNITION_TABLE = {
    RECEIVED: {
        SORTCOLUMNS: ['date', 'from', 'Value', 'reward-type', 'message'],
        HEADERS: ['Date', 'From', 'Value', 'Reward Type', 'Message'],
    },
    GIVEN: {
        SORTCOLUMNS: ['date', 'to', 'reward-type', 'message', 'status'],
        HEADERS: ['Date', 'To', 'Reward Type', 'Message', 'Status'],
    },
    REJECTED: {
        SORTCOLUMNS: ['date', 'from', 'to', 'reward-type', 'rejectionReason'],
        HEADERS: ['Rejection Date', 'From', 'To', 'Reward Type', 'Reason'],
    },
};

export const ACTIVITIY_PATHS = {
    ACTIVITIES: '/dashboard/activities',
};

export const RECOGNITION_NAME = {
    SPECIAL_REWARDS: 'special rewards',
    BONUS: 'bonus',
};
