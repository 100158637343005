import React from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import clsx from 'clsx';
import './style.scss';

/**
 * Pass modal data in the folowing format
 *  const modalData = {
        heading: '360º Feedback',
        subHeading: 'Share constructive and actionable feedback with your co-workers',
        rightButtonText: 'Send',
        handleRightButtonClick: () => {},
        leftButtonText: 'Cancel',
        handleLeftButtonClick: () => {},
    };
 *
 */

const Index = ({
    open,
    onClose,
    width = 'auto',
    height = 'auto',
    modalData,
    customClassName,
    showCloseButton = true,
    children,
}) => {
    if (!open) {
        return null;
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div
                className={clsx({ 'ew-modal-container': true, [customClassName]: customClassName })}
                style={{ width, height }}
            >
                <div
                    className={clsx({
                        'ew-modal-heading d-flex': true,
                        'justify-content-between': modalData?.heading,
                        'justify-content-end': !modalData?.heading,
                        'pb-3': !showCloseButton,
                    })}
                >
                    {modalData?.heading}
                    {showCloseButton && (
                        <CloseIcon data-testid='ew-modal-btn-close' className='pointer-cursor' onClick={onClose} />
                    )}
                </div>
                {modalData?.subHeading && <p className='ew-modal-subheading'>{modalData.subHeading}</p>}
                {children}
                {(modalData?.leftButtonText || modalData?.rightButtonText) && (
                    <div className='ew-modal-button-container'>
                        <EWButton
                            buttonStyleClass='ew-modal-left-button'
                            buttonText={modalData?.leftButtonText}
                            primary={false}
                            plainTextButton
                            disabled={modalData?.loading}
                            onClick={modalData?.handleLeftButtonClick}
                        />
                        <EWButton
                            buttonStyleClass='ml-4'
                            buttonText={modalData?.rightButtonText}
                            onClick={modalData?.handleRightButtonClick}
                            loading={modalData?.loading}
                            disabled={modalData?.disabled || modalData?.loading}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modalData: PropTypes.object,
    loading: PropTypes.bool,
    customClassName: PropTypes.string,
    showCloseButton: PropTypes.bool,
    children: PropTypes.node,
};

export default Index;
