import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';
import { patchData } from 'Services/apiCall';
import { isEmptyObject, showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import PointsName from './PointsName';
import RecognitionSetup from './RecognitionSetup';
import Balance from './Balance';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import clsx from 'clsx';
import './style.scss';
import { ReactComponent as Warning } from 'Assets/images/warning-yellow.svg';
import SaveConfirmation from 'components/Modals/SaveConfirmationModal';
import { Prompt, useLocation } from 'react-router-dom';
import { promptMessage, orgWideApproverText } from '../constants';
import { GET_CONFIG_POINTS, GET_ADMIN_LIST, GET_RECOGNITION_RESTRICTED_USER } from 'Services/apiKeys';
import { getPointsConfig, getAdminList } from 'Services/apiFunctions';
import { getRecognitionsRestrictedUser } from '../../Services/apiFunctions';
import { filter, find } from 'lodash';
import PermissionSection from './PermissionSection';
import EWButton from 'components/ReusableComponents/EWButton';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { Approver, ChangeApprover, OrgSettings } from 'components/ReusableComponents/Settings';

const Index = ({ saveConfirm, modified, handleChange, history }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const { config, nameForPoints, approver } = useSelector(mapStateToProps, shallowEqual);
    const Location = useLocation();
    const [data, setData] = useState({});
    const [customPoints, setCustomPoints] = useState(nameForPoints);
    const [balance, setBalance] = useState({});
    const [rewards, setRewards] = useState();
    const [saveLoading, setSaveLoading] = useState(false);
    const [approverOpen, setApproverOpen] = useState(false);
    const [error, setError] = useState(false);
    const feature = 'recognition';

    const orgWideApproverSection = [
        {
            heading: 'Organization-wide Approver',
            subtext: (
                <>
                    {orgWideApproverText[0]}
                    <EWButton
                        plainTextButton={true}
                        primary={false}
                        buttonStyleClass='ob-users-link'
                        onClick={() => history.push('/dashboard/users')}
                    >
                        Users page
                    </EWButton>
                    {orgWideApproverText[1]}
                </>
            ),
            button: approver?.approverName ? 'Change Approver' : 'Add Approver',
            component: approver?.approverName ? <Approver /> : <></>,
        },
    ];

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);
    const onSuccess = (pointsConfigData) => {
        setData(pointsConfigData);
        getInitialData(pointsConfigData);
    };

    const { data: adminData } = useQuery([GET_ADMIN_LIST], getAdminList, onError);

    const { data: recognitionRestrictedUser, isLoading: restrictedUserLoading } = useQuery(
        [GET_RECOGNITION_RESTRICTED_USER, feature],
        getRecognitionsRestrictedUser,
        onError
    );

    const {
        data: pointsData,
        isLoading,
        isFetching,
    } = useQuery([GET_CONFIG_POINTS], getPointsConfig, { onSuccess, onError });

    const editSettings = async (apiData) => {
        setSaveLoading(true);
        const apiDataLength = Object.keys(apiData).length;
        if (apiDataLength > 0) {
            try {
                await patchData('recognition/configure/points', apiData);
                showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully');
                updateWorkspaceDetails(dispatch, { nameForPoints: customPoints });
                handleChange('modified', false);
                saveConfirm && handleChange('changeTab');
                handleChange('saveConfirm', false);
                queryClient.invalidateQueries(GET_CONFIG_POINTS);
            } catch (err) {
                showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
            } finally {
                setSaveLoading(false);
            }
        }
    };

    const checkConditions = [
        {
            condition: find(rewards, (item) => !item.points),
            errMsg: `Point value for recognitions must be greater than 0`,
        },
        {
            condition: balance?.creditPoints === '' && data?.creditPoints !== 0,
            errMsg: 'Enter the maximum points a user can reward in a month',
        },
        {
            condition: balance.carryForward && balance.maxPoints === '',
            errMsg: 'Enter the maximum cap for points a user can hold',
        },
        {
            condition: balance.carryForward && balance.maxPoints === 0,
            errMsg: 'Maximum cap for points a user can hold cannot be 0',
        },
        {
            condition: customPoints === '',
            errMsg: 'Enter a custom name for point',
        },
    ];

    const checkFieldStatus = ({ condition, errorMessage }) => {
        if (condition) {
            showSnackBarMessage(SetSnackbar, 'warning', errorMessage);
        }
    };

    const apiDataUpdate = (apiData) => {
        let dataModified = false;
        if (data?.pointName !== customPoints) {
            apiData.name = customPoints;
            dataModified = true;
        }
        if (data?.creditPoints !== balance?.creditPoints) {
            apiData.creditPoints = balance?.creditPoints;
            dataModified = true;
        }
        const carryForwardModified = data?.carryForward?.enabled !== balance.carryForward;
        const maxPointsModified = balance.carryForward && data?.carryForward?.maxPoints !== balance.maxPoints;
        if (carryForwardModified || maxPointsModified) {
            apiData.carryForward = {
                enabled: balance.carryForward,
                ...(maxPointsModified && { maxPoints: balance.maxPoints }),
            };
            dataModified = true;
        }
        const modifiedRewards = filter(rewards, (item) => item.modified);
        if (modifiedRewards.length) {
            apiData.types = modifiedRewards.map((obj) => {
                return {
                    _id: obj._id,
                    rewardType: obj.name,
                    points: obj?.points,
                    isApprovalRequired: obj?.isApprovalRequired || false,
                };
            });
            dataModified = true;
        }
        handleChange('modified', !isFetching && !isLoading && dataModified);
    };

    const handleSave = (save) => {
        if (save && balance.carryForward && !balance.maxPoints) {
            setError(true);
            return;
        }
        let checkConditionStatus = false;

        for (const conditionField of checkConditions) {
            checkFieldStatus({
                condition: save && conditionField?.condition,
                errorMessage: conditionField?.errMsg,
            });
            if (save && conditionField?.condition) {
                checkConditionStatus = true;
                break;
            }
        }
        if (checkConditionStatus) return;
        const apiData = {};
        apiDataUpdate(apiData);
        save && editSettings(apiData);
        setError(false);
    };

    const getInitialData = (data) => {
        if (!isEmptyObject(data)) {
            if (data?.types?.length > 0) {
                setRewards(data.types.map((item) => ({ ...item, modified: false })));
            }
            setBalance({
                creditPoints: data?.creditPoints,
                carryForward: data?.carryForward?.enabled,
                maxPoints: data?.carryForward?.maxPoints,
            });
            setCustomPoints(data?.pointName);
        }
    };

    const handleCancel = () => {
        handleChange('saveConfirm', false);
        handleChange('changeTab');
        setCustomPoints(nameForPoints);
        getInitialData(pointsData);
    };

    useEffect(() => {
        handleSave();
    }, [config, customPoints, nameForPoints, rewards, balance]);

    if (!data) {
        return <CustomLoader />;
    }

    return (
        <div className={clsx({ 'config-rewards': true, 'pointer-events-none': saveLoading })}>
            <div className='config-rewards-container'>
                <RecognitionSetup
                    nameForPoints={nameForPoints}
                    rewards={rewards}
                    setRewards={setRewards}
                    kudosName={config?.kudosName}
                    shoutoutName={config?.shoutoutName}
                />
                <OrgSettings
                    blockData={orgWideApproverSection[0]}
                    key={orgWideApproverSection[0].heading}
                    index={0}
                    handleOpenModal={() => setApproverOpen(true)}
                />
                <Balance nameForPoints={nameForPoints} balance={balance} setBalance={setBalance} error={error} />
                <PermissionSection data={recognitionRestrictedUser} dataLoading={restrictedUserLoading} />
                <PointsName customPoints={customPoints} setCustomPoints={setCustomPoints} />
            </div>
            {!isLoading && modified && (
                <div className='config-rewards-button-container'>
                    <div className='action-buttons'>
                        <Warning /> Careful, you have unsaved changes
                        <EWButton
                            buttonStyleClass='ml-3'
                            buttonText='Save Changes'
                            onClick={() => handleSave(true)}
                            loading={saveLoading}
                            disabled={saveLoading}
                        />
                    </div>
                </div>
            )}
            <SaveConfirmation
                open={!!(data && modified && saveConfirm)}
                setOpen={(value) => handleChange('saveConfirm', value)}
                loading={saveLoading}
                handleConfirm={() => handleSave(true)}
                handleCancel={handleCancel}
            />

            <Prompt when={!!(modified && Location.pathname === '/dashboard/configuration')} message={promptMessage} />
            {approverOpen && (
                <ChangeApprover
                    open={approverOpen}
                    setOpen={setApproverOpen}
                    initialAdminCount={adminData?.data.length}
                />
            )}
        </div>
    );
};
const mapStateToProps = ({ Workspace }) => ({
    nameForPoints: Workspace.nameForPoints,
    config: Workspace.config,
    approver: Workspace.approver,
});

Index.propTypes = {
    saveConfirm: PropTypes.bool,
    modified: PropTypes.bool,
    handleChange: PropTypes.func,
    history: PropTypes.object,
};

export default Index;
